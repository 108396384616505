<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script setup>
import { onUnmounted, onBeforeMount, onMounted } from 'vue';
import { useStore } from "vuex";
import Smartlook from 'smartlook-client';

const store = useStore();

const modalsController = (keyCode) => {
  if (keyCode === 27) store.dispatch('resetModalStates');
  if (keyCode === 37) store.dispatch('modals/prevModal');
  if (keyCode === 39) store.dispatch('modals/nextModal');
};

onBeforeMount(() => {
  setTimeout(() => {
    store.dispatch('init');
    document.addEventListener('keyup', (e) => modalsController(e.keyCode));
  }, 1000);
});

onMounted(() => {
  Smartlook.init('8c903982d0581e97c22a897eab351e5e7391c43f', { region: 'eu' });
})

onUnmounted(() => {
  document.removeEventListener('keyup', modalsController);
});
</script>
