import { createStore } from "vuex";
import auth from "./auth";
import language from "./language";
import profile from "./profile";
import vehiclebrands from "./vehiclebrands";
import modals from "./modals";
import faq from "./faq";
import prices from "./prices";
import testimonials from "./testimonials";
import pages from "./pages";
import blog from "./blog";
import geoIP from "./geoIP";
import device from "./device";

// import Auth from "../services/auth.service";
import Country from "@/utils/country";

export const store = createStore({
  modules: {
    auth,
    language,
    profile,
    vehiclebrands,
    faq,
    modals,
    prices,
    testimonials,
    pages,
    blog,
    geoIP,
    device,
  },
  state: {
    loading: true,
    allowVendorsModalEventEmit: true,
  },
  mutations: {
    loading(state, payload) {
      state.loading = payload;
    },
    setAllowVendorsModalEvent(state, payload) {
      state.allowVendorsModalEventEmit = payload;
    },
  },
  actions: {
    async init({ commit, dispatch, rootState: { geoIP } }) {
      await dispatch("geoIP/getIP");
      const countryCode = geoIP?.geoIP?.countryCode;

      dispatch("prices/getPrices", countryCode);

      const faqList = dispatch("faq/getFaq");
      const testimonialsList = dispatch("testimonials/getTestimonials");
      const pages = dispatch("pages/getPages");

      Promise.all([faqList, testimonialsList, pages]).then(() => {
        commit("loading", false);
      });

      // Auth.isAuthenticated();
    },
    resetModalStates({ dispatch }) {
      dispatch("modals/resetAll");
    },
    resetState({ dispatch }) {
      // dispatch("profile/resetState");
      // dispatch("auth/resetState");
    },
    changeLang({ dispatch }, lang) {
      dispatch("language/changeLanguage", lang);
    },
    setAllowVendorsModalEvent({ commit }, payload) {
      commit('setAllowVendorsModalEvent', payload);
    },
  },
  getters: {},
});
